import {ScreensEnum, ScreensTypeEnum} from "@/Screens.enum";
import {ScreenDefinitions} from "@/Screens.types";
import {markRaw} from "vue";
import ScreenQuestionSlider from "@/components/screens/ScreenQuestionSlider.vue";
import QuestionTwoButtonsScreen from "@/components/screens/ScreenQuestionTwoButtons.vue";
import ScirtScreenIntro from "@/components/screens/ScirtScreenIntro.vue";
import ScreenQuestionSortList from "@/components/screens/ScreenQuestionSortList.vue";
import ScreenQuestionButtons from "@/components/screens/ScreenQuestionButtons.vue";
import ScreenCountriesDropdown from "@/components/screens/ScreenCountriesDropdown.vue";
import ScreenSeeMoreLabelWithButtons from "@/components/screens/ScreenSeeMoreLabelWithButtons.vue";
import ScreenGreenAnswer from "@/components/screens/ScreenGreenAnswer.vue";
import ScreenQuestionButtonsGreen from "@/components/screens/ScreenQuestionButtonsGreen.vue";
import ScreenAnswer from "@/components/screens/ScreenAnswer.vue";
import ScreenQuestionMultiSlider from "@/components/screens/ScreenQuestionMultiSlider.vue";
import ScreenQuestionOptionsGreen from "@/components/screens/ScreenQuestionOptionsGreen.vue";
import ScreenLightGreenAnswer from "@/components/screens/ScreenLightGreenAnswer.vue";
import ScreenDragDropCircles from "@/components/screens/ScreenDragDropCircles.vue";
import ScreenRightSlides from "@/components/screens/ScreenRightSlides.vue";
import ScreenTextsPink from "@/components/screens/ScreenTextsPink.vue";
import ScreenTextsGreen from "@/components/screens/ScreenTextsGreen.vue";
import ScreenSingleQuestion from "@/components/screens/ScreenSingleQuestion.vue";
import ScreenRateUs from "@/components/screens/ScreenRateUs.vue";
import ScreenFinalVideo from "@/components/screens/ScreenFinalVideo.vue";
import ScreenChartPink from "@/components/screens/ScreenChartPink.vue";

export const getScreens = ($t, state, locale = 'en'): ScreenDefinitions => {
    return {
        [ScreensEnum.SCIRT_INTRO]: {
            component: markRaw(ScirtScreenIntro),
            screenId: ScreensEnum.SCIRT_INTRO,
            type: ScreensTypeEnum.SCREEN_INTRO,
            title: '',
            answerKey: 'SCIRT_INTRO',
            bgImage: "url('img/scirt/rjuha.png')",
            image1: "img/scirt/dekle1.png",
            textPart1: 'intro.par1',
            textPart3: 'intro.par3',
            textPart4: 'intro.beforeButton',
            placeholderNickname: 'intro.nickname',
            screenNext: ScreensEnum.LastShopping,
        },

        [ScreensEnum.LastShopping]: {
            component: markRaw(QuestionTwoButtonsScreen),
            screenId: ScreensEnum.LastShopping,
            screenNext: ScreensEnum.BuyingCriteria,
            type: ScreensTypeEnum.SCREEN_QUESTION_TWO_BUTTONS,
            title: 'Q1',
            bgImage: "url('img/scirt/man1.png')",
            answerKey: 'shopping',
            textPart1: 'LastShopping.text1',
            textPart2: 'LastShopping.text2',
            question: 'LastShopping.question',
            options: {
                'answer1': 'LastShopping.answer1',
                'answer2': 'LastShopping.answer2',
            }
        },

        [ScreensEnum.BuyingCriteria]: {
            component: markRaw(ScreenQuestionSortList),
            screenId: ScreensEnum.BuyingCriteria,
            screenNext: ScreensEnum.HowMuchSpendOnJeans,
            type: ScreensTypeEnum.SCREEN_SORT_OPTIONS,
            title: 'Q2',
            textPart1: 'BuyingCriteria.text1',
            textPart2: 'BuyingCriteria.hint',
            question: 'BuyingCriteria.question',
            options: {
                price: 'BuyingCriteria.price',
                esthetics: 'BuyingCriteria.esthetics',
                ecofriendly: 'BuyingCriteria.ecofriendly',
                quality: 'BuyingCriteria.quality',
                material: 'BuyingCriteria.material',
                // other: 'BuyingCriteria.other',
                fair: 'BuyingCriteria.fairAndHumane',
            },
            answerKey: 'BuyingCriteria',
            bgImage: "url('img/scirt/stand.jpg')",
        },

        [ScreensEnum.HowMuchSpendOnJeans]: {
            screenId: ScreensEnum.HowMuchSpendOnJeans,
            screenNext: ScreensEnum.WhatToPayForPants,
            type: ScreensTypeEnum.SCREEN_SLIDER_PINK,
            title: 'Q3',
            textPart1: 'HowMuchSpendOnJeans.text1',
            question: 'HowMuchSpendOnJeans.question',
            answerKey: 'HowMuchSpendOnJeans',
            answerMin: 5,
            answerMax: 330,
            answerStep: 25,
            answerUnit: '€',
            instant: true,
            bgImage: "url('img/scirt/stack1.png')",
            bgStyles: 'background-position: bottom; background-size: contain;',
            component: markRaw(ScreenQuestionSlider),
        },

        [ScreensEnum.WhatToPayForPants]: {
            screenId: ScreensEnum.WhatToPayForPants,
            screenNext: ScreensEnum.HowMuchSwimmwear,
            type: ScreensTypeEnum.SCREEN_SLIDER_PINK,
            title: 'Q4',
            // textPart1: 'HowMuchSpendOnJeans.text1',
            question: 'WhatToPayForPants.question',
            answerKey: 'WhatToPayForPants',
            answerMin: 5,
            answerMax: 330,
            answerStep: 25,
            instant: true,
            answerUnit: '€',
            bgImage: "url('img/scirt/stack2.png')",
            bgStyles: 'background-position: bottom; background-size: contain;',
            component: markRaw(ScreenQuestionSlider),
        },

        [ScreensEnum.HowMuchSwimmwear]: {
            screenId: ScreensEnum.HowMuchSwimmwear,
            screenNext: ScreensEnum.InitalQuestionsThankYou,
            type: ScreensTypeEnum.SCREEN_SLIDER_PINK,
            title: 'Q5',
            // textPart1: 'HowMuchSpendOnJeans.text1',
            question: 'HowMuchSwimmwear.question',
            answerKey: 'HowMuchSwimmwear',
            answerMin: 5,
            answerMax: 330,
            answerStep: 25,
            answerUnit: '€',
            instant: true,
            bgImage: "url('img/scirt/stack3.png')",
            bgStyles: 'background-position: bottom; background-size: contain;',
            component: markRaw(ScreenQuestionSlider),
        },

        [ScreensEnum.InitalQuestionsThankYou]: {
            screenId: ScreensEnum.InitalQuestionsThankYou,
            screenNext: ScreensEnum.AgeGroup,
            type: ScreensTypeEnum.SCREEN_TEXTS,
            title: 'Q6',
            answerKey: 'InitalQuestionsThankYou',
            textPart1: 'InitalQuestionsThankYou.text1',
            textPart2: 'InitalQuestionsThankYou.text11',
            textPart3: 'InitalQuestionsThankYou.text2',
            textPart4: 'InitalQuestionsThankYou.text3',
            nextButtonText: 'buttons.go',
            bgImage: "url('img/scirt/dekle2.png')",
            bgStyles: 'background-position: bottom; background-size: contain;',
            component: markRaw(ScreenTextsPink),
        },

        [ScreensEnum.AgeGroup]: {
            screenId: ScreensEnum.AgeGroup,
            screenNext: ScreensEnum.Country,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: '',
            question: 'AgeGroup.question',
            answerKey: 'AgeGroup',
            maxRows: 4,
            options: {
                'below-18': 'AgeGroup.below-18',
                '18-30': 'AgeGroup.18-30',
                '30-40': 'AgeGroup.30-40',
                '40-50': 'AgeGroup.40-50',
                '50-60': 'AgeGroup.50-60',
                '60-70': 'AgeGroup.60-70',
                'above-70': 'AgeGroup.above-70',
            },
            component: markRaw(ScreenQuestionButtons),
            bgImage: "url('img/scirt/nitiozadje.jpg')",
            bgStyles: 'background-size: cover;',
        },

        [ScreensEnum.Country]: {
            screenId: ScreensEnum.Country,
            screenNext: ScreensEnum.Gender,
            type: ScreensTypeEnum.SCREEN_OPTIONS_COUNTRIES,
            title: '',
            question: 'Country.question',
            answerKey: 'Country',
            selected: 'de',
            component: markRaw(ScreenCountriesDropdown),
            bgImage: "url('img/scirt/mapbg.jpg')",
            bgStyles: 'background-color: #F4E8E6;'
        },

        [ScreensEnum.Gender]: {
            screenId: ScreensEnum.Gender,
            screenNext: ScreensEnum.WaterNeededForShirtQuestion,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: '',
            answerKey: 'Gender',
            question: 'Gender.question',
            bgImage: "url('img/scirt/page9bg.jpg')",
            options: {
                female: 'Gender.female',
                male: 'Gender.male',
                'non-binary': 'Gender.non-binary',
                'not-say': 'Gender.not-say',
            },
            component: markRaw(ScreenQuestionButtons),
        },

        [ScreensEnum.WaterNeededForShirtQuestion]: {
            screenId: ScreensEnum.WaterNeededForShirtQuestion,
            screenNext: ScreensEnum.WaterNeededForShirtAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS_GREEN_TOGGLE_QUESTION,
            title: 'Q7',
            bgImage: "img/scirt/gifi/majca_gif.gif",
            textPart1: 'WaterNeededForShirt.text1',
            textPart2: 'WaterNeededForShirt.text2',
            question: 'WaterNeededForShirt.question',
            image1: "url('img/scirt/label.png')",
            answerKey: 'WaterNeededForShirt.answer',
            options: {
                80: '80 L', 340: '340 L', 1500: '1500 L', 2700: '2700 L'
            },
            component: markRaw(ScreenSeeMoreLabelWithButtons),
        },
        [ScreensEnum.WaterNeededForShirtAns]: {
            screenId: ScreensEnum.WaterNeededForShirtAns,
            screenNext: ScreensEnum.Emissions,
            type: ScreensTypeEnum.SCREEN_ANSWER_GREEN,
            title: '',
            answerKey: 'WaterNeededForShirt.answer',
            correctAnswer: [2700, '2700 L'],
            titleAnsOk: 'WaterNeededForShirt.ans_ok',
            titleAnsWrong: 'WaterNeededForShirt.ans_nok',
            image1: 'img/scirt/fact.png',
            textAnsOk: 'WaterNeededForShirt.ans_text',
            textAnsWrong: 'WaterNeededForShirt.ans_nok_text',
            textPart2: 'WaterNeededForShirt.fact',
            bgImage: "img/scirt/gifi/majca_gif.gif",
            bgStyles: '',
            component: markRaw(ScreenGreenAnswer),
        },

        [ScreensEnum.Emissions]: {
            screenId: ScreensEnum.Emissions,
            screenNext: ScreensEnum.EmissionsAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: 'Q8',
            answerKey: 'Emissions',
            question: 'Emissions.question',
            options: {
                '2-5': '2% - 5%',
                '8-10': '8% - 10%',
                '12-15': '12% - 15%',
            },
            bgImage: "url('img/scirt/gifi/emisije_gif.gif')",
            component: markRaw(ScreenQuestionButtonsGreen),
        },
        [ScreensEnum.EmissionsAns]: {
            screenId: ScreensEnum.EmissionsAns,
            screenNext: ScreensEnum.HowManyPeopleEmployed,
            type: ScreensTypeEnum.SCREEN_ANSWER_GREEN_WITH_HIGH_LOW,
            title: '',
            bgImage: "url('img/scirt/q6bg.jpg')",
            bgStyles: 'background-color: #D2EFE1;',
            answerKey: 'Emissions',
            correctAnswer: ['2-5', '2% - 5%'],
            hideCorrectAnswer: true,
            titleAnsOk: 'Emissions.ans_ok',
            textAnsOk: 'Emissions.ans_ok_text',
            titleAnsWrong: 'Emissions.ans_nok',
            textAnsWrong: 'Emissions.ans_nok_text',
            source: {
                text: 'Emissions.source',
                link: 'https://www.wri.org/research/roadmap-net-zero-delivering-science-based-targets-apparel-sector'
            },
            component: markRaw(ScreenLightGreenAnswer),
        },

        [ScreensEnum.HowManyPeopleEmployed]: {
            screenId: ScreensEnum.HowManyPeopleEmployed,
            screenNext: ScreensEnum.HowManyPeopleEmployedAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: 'Q9',
            answerKey: 'HowManyPeopleEmployed',
            question: 'HowManyPeopleEmployed.question',
            bgImage: "url('img/scirt/mandress.jpg')",
            options: {
                100: 'HowManyPeopleEmployed.100mil',
                200: 'HowManyPeopleEmployed.200mil',
                300: 'HowManyPeopleEmployed.300mil',
                400: 'HowManyPeopleEmployed.400mil',
            },
            component: markRaw(ScreenQuestionButtons),
        },
        [ScreensEnum.HowManyPeopleEmployedAns]: {
            screenId: ScreensEnum.HowManyPeopleEmployedAns,
            screenNext: ScreensEnum.WhereDoYouBuyClothes,
            type: ScreensTypeEnum.SCREEN_ANSWER,
            title: '',
            answerKey: 'HowManyPeopleEmployed',
            bgImage: "img/scirt/gifi/ljudje.gif",
            correctAnswer: [300, 'HowManyPeopleEmployed.300mil'],
            titleAnsOk: 'HowManyPeopleEmployed.ans_ok_title',
            titleAnsWrong: 'HowManyPeopleEmployed.ans_nok_title',
            hideCorrectAnswer: true,
            textAnsOk: 'HowManyPeopleEmployed.ans_ok_text',
            textAnsWrong: 'HowManyPeopleEmployed.ans_nok_text',
            source: {
                text: 'HowManyPeopleEmployed.source',
            },
            component: markRaw(ScreenAnswer),
        },

        [ScreensEnum.WhereDoYouBuyClothes]: {
            screenId: ScreensEnum.WhereDoYouBuyClothes,
            screenNext: ScreensEnum.ShoppingHabits,
            type: ScreensTypeEnum.SCREEN_SLIDER_PINK,
            title: 'Q10',
            question: 'WhereDoYouBuyClothes.question',
            answerKey: 'WhereDoYouBuyClothes',
            answerUnit: '',
            textPart1: 'WhereDoYouBuyClothes.text1',
            answerOptions: {
                '1-physical': 'WhereDoYouBuyClothes.physical',
                '2-': '',
                '3-': '',
                '4-': '',
                '5-online': 'WhereDoYouBuyClothes.online',
            },
            bgImage: "url('img/scirt/selectingclothes.jpg')",
            component: markRaw(ScreenQuestionSlider),
        },

        [ScreensEnum.ShoppingHabits]: {
            screenId: ScreensEnum.ShoppingHabits,
            screenNext: ScreensEnum.HowManyProduced,
            type: ScreensTypeEnum.SCREEN_SLIDER_PINK_MULTI,
            title: 'Q11',
            questions: {
                question1: 'ShoppingHabits.question1',
                question2: 'ShoppingHabits.question2',
                question3: 'ShoppingHabits.question3',
                question4: 'ShoppingHabits.question4',
                question5: 'ShoppingHabits.question5',
            },
            answerKey: 'ShoppingHabits',
            answerUnit: '',
            textPart1: 'ShoppingHabits.intro',
            // textPart2: 'ShoppingHabits.text2',
            // textPart3: 'ShoppingHabits.text3',
            answerOptions: {
                'never': 'ShoppingHabits.never',
                'rarely': 'ShoppingHabits.rarely',
                'sometimes': 'ShoppingHabits.sometimes',
                'often': 'ShoppingHabits.often',
                'always': 'ShoppingHabits.always',
            },
            additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            // bgImage: "img/scirt/cookiesclothes.png",
            component: markRaw(ScreenQuestionMultiSlider),
        },

        [ScreensEnum.HowManyProduced]: {
            screenId: ScreensEnum.HowManyProduced,
            screenNext: ScreensEnum.HowManyProducedAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: 'Q12',
            answerKey: 'HowManyProduced',
            textPart1: 'HowManyProduced.intro',
            question: 'HowManyProduced.question',
            bgImage: "url('img/scirt/gifi/gumbki-in-svet.gif')",
            options: {
                '500-600': 'HowManyProduced.500-600mil',
                '1-1.5': 'HowManyProduced.1-15bil',
                '50-60': 'HowManyProduced.50-60bil',
                '100-150': 'HowManyProduced.100-150bil',
            },
            component: markRaw(ScreenQuestionButtonsGreen),
        },
        [ScreensEnum.HowManyProducedAns]: {
            screenId: ScreensEnum.HowManyProducedAns,
            screenNext: ScreensEnum.HowOftenConsiderSustainability,
            type: '',
            title: '',
            answerKey: 'HowManyProduced',
            bgImage: "url('img/scirt/q9bg.jpg')",
            bgStyles: 'background-color: #D8F5E7;',
            correctAnswer: ['100-150', 'HowManyProduced.100-150bil'],
            textAnsOk: 'HowManyProduced.ans_ok_text',
            textAnsWrong: 'HowManyProduced.ans_nok_text',
            titleAnsOk: 'HowManyProduced.ans_ok_title',
            hideCorrectAnswer: true,
            titleAnsWrong: 'HowManyProduced.ans_nok_title',
            source: {
                'text': 'HowManyProduced.source',
            },
            component: markRaw(ScreenLightGreenAnswer),
        },

        [ScreensEnum.HowOftenConsiderSustainability]: {
            screenId: ScreensEnum.HowOftenConsiderSustainability,
            screenNext: ScreensEnum.HowYouCompare,
            type: ScreensTypeEnum.SCREEN_OPTIONS_RADIO_BUTTONS,
            title: 'Q13',
            answerKey: 'HowOftenConsiderSustainability',
            question: 'HowOftenConsiderSustainability.question',
            bgImage: "url('img/scirt/statve.png')",
            options: {
                'idont': 'HowOftenConsiderSustainability.idont',
                'rarely': 'HowOftenConsiderSustainability.rarely',
                'somewhat': 'HowOftenConsiderSustainability.somewhat',
                'often': 'HowOftenConsiderSustainability.often',
                'always': 'HowOftenConsiderSustainability.always',
            },
            component: markRaw(ScreenQuestionOptionsGreen),
        },

        [ScreensEnum.HowYouCompare]: {
            screenId: ScreensEnum.HowYouCompare,
            screenNext: ScreensEnum.DragDropShirt,
            type: ScreensTypeEnum.SCREEN_TEXTS,
            title: '',
            textPart1: 'HowYouCompare.text1',
            textPart2: 'HowYouCompare.text2',
            answerKey: 'HowOftenConsiderSustainability',
            chartLabelsPrefix: 'HowOftenConsiderSustainability',
            chartLabel: 'HowYouCompare.chart-label',
            chartSeries: [
                {name: 'AgeGroup.title', filter: 'AgeGroup', prefix: 'AgeGroup'},
                {name: 'Gender.title', filter: 'Gender', prefix: 'Gender'},
            ],
            component: markRaw(ScreenChartPink),
        },

        [ScreensEnum.DragDropShirt]: {
            screenId: ScreensEnum.DragDropShirt,
            screenNext: ScreensEnum.NotSoldGarments,
            type: ScreensTypeEnum.SCREEN_OPTIONS_DRAGDROP,
            title: 'Q14',
            question: 'DragDropShirt.question',
            textPart1: 'DragDropShirt.dragShirtText',
            image1: "img/scirt/q13_shirt.png",
            answerKey: 'DragDropShirt',
            options: {
                'secondhand': 'DragDropShirt.secondhand',
                'garbage': 'DragDropShirt.garbage',
                gift: 'DragDropShirt.gift',
                reuse: 'DragDropShirt.reuse',
                keep: 'DragDropShirt.keep',
                container: 'DragDropShirt.container',
                charity: 'DragDropShirt.charity',
                bringback: 'DragDropShirt.bringback',
            },
            optionsOther: {
                enabled: true,
                key: 'other',
                text: 'DragDropShirt.other-text',
                value: 'DragDropShirt.other',
            },
            optionsImages: {
                secondhand: "img/scirt/q13_1.jpg",
                garbage: "img/scirt/q13_2.jpg",
                gift: "img/scirt/q13_3.jpg",
                reuse: "img/scirt/q13_4.jpg",
                keep: "img/scirt/ddwardrobe.png",
                container: "img/scirt/q13_5.jpg",
                charity: "img/scirt/ddcharity.png",
                bringback: "img/scirt/ddbringback.png",
                other: `img/scirt/${locale}/ddideas.png`,
            },
            component: markRaw(ScreenDragDropCircles),
        },

        [ScreensEnum.NotSoldGarments]: {
            screenId: ScreensEnum.NotSoldGarments,
            screenNext: ScreensEnum.NotSoldGarmentsAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS_BUTTONS,
            title: 'Q15',
            answerKey: 'NotSoldGarments',
            question: 'NotSoldGarments.question',
            textPart1: 'NotSoldGarments.intro',
            bgImage: "url('img/scirt/junkyard.jpg')",
            bgStyles: 'background-size: cover;',
            options: {
                '10': 'NotSoldGarments.10-percent',
                '20': 'NotSoldGarments.20-percent',
                '30': 'NotSoldGarments.30-percent',
                '40': 'NotSoldGarments.40-percent',
            },
            component: markRaw(ScreenQuestionButtonsGreen),
        },


        [ScreensEnum.NotSoldGarmentsAns]: {
            screenId: ScreensEnum.NotSoldGarmentsAns,
            screenNext: ScreensEnum.HowMuchClothesBoughtKg,
            type: ScreensTypeEnum.SCREEN_ANSWER_GREEN,
            title: '',
            answerKey: 'NotSoldGarments',
            hideCorrectAnswer: true,
            correctAnswer: ['30', 'NotSoldGarments.30-percent'],
            textAnsOk: 'NotSoldGarments.ans_ok_text',
            textAnsWrong: 'NotSoldGarments.ans_nok_text',
            titleAnsOk: 'NotSoldGarments.ans_ok_title',
            titleAnsWrong: 'NotSoldGarments.ans_nok_title',
            textPart1: 'NotSoldGarments.ans_text',
            bgImage: "url('img/scirt/gifi/nalagajoce-obleke.gif')",
            source: {
                text: 'NotSoldGarments.source',
            },
            component: markRaw(ScreenLightGreenAnswer),
        },

        [ScreensEnum.HowMuchClothesBoughtKg]: {
            screenId: ScreensEnum.HowMuchClothesBoughtKg,
            screenNext: ScreensEnum.HowMuchClothesBoughtKgAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS,
            title: 'Q16',
            answerKey: 'HowMuchClothesBoughtKg',
            question: 'HowMuchClothesBoughtKg.question',
            options: {
                5: 'HowMuchClothesBoughtKg.5kg',
                9: 'HowMuchClothesBoughtKg.9kg',
                15: 'HowMuchClothesBoughtKg.15kg',
                26: 'HowMuchClothesBoughtKg.26kg',
            },
            bgImage: "url('img/scirt/q11bg.jpg')",
            bgStyles: 'background-size: contain;',
            component: markRaw(ScreenQuestionButtons),

        },
        [ScreensEnum.HowMuchClothesBoughtKgAns]: {
            screenId: ScreensEnum.HowMuchClothesBoughtKgAns,
            screenNext: ScreensEnum.HowManyTShirts,
            type: ScreensTypeEnum.SCREEN_ANSWER,
            title: '',
            answerKey: 'HowMuchClothesBoughtKg',
            hideCorrectAnswer: true,
            correctAnswer: [26, '26 kg'],
            titleAnsOk: 'HowMuchClothesBoughtKg.ans_ok_title',
            titleAnsWrong: 'HowMuchClothesBoughtKg.ans_nok_title',
            textAnsOk: 'HowMuchClothesBoughtKg.ans_ok_text',
            textAnsWrong: 'HowMuchClothesBoughtKg.ans_nok_text',
            textPart1: 'HowMuchClothesBoughtKg.ans_text',
            textPart2: 'HowMuchClothesBoughtKg.ans_text2',
            bgImage: "img/scirt/gifi/utezi.gif",
            rightSideClasses: 'bg-project',
            source: {
                text: 'HowMuchClothesBoughtKg.source',
            },
            component: markRaw(ScreenAnswer),
        },

        [ScreensEnum.HowManyTShirts]: {
            screenId: ScreensEnum.HowManyTShirts,
            screenNext: ScreensEnum.HowManyTShirtsAns,
            type: ScreensTypeEnum.SCREEN_OPTIONS,
            title: 'Q17',
            question: 'HowManyTShirts.question',
            options: {
                20: 'HowManyTShirts.20-shirts',
                40: 'HowManyTShirts.40-shirts',
                60: 'HowManyTShirts.60-shirts',
                75: 'HowManyTShirts.75-shirts',
            },
            answerKey: 'HowManyTShirts',
            bgImage: "url('img/scirt/q12bg.jpg')",
            source: {
                text: 'HowManyTShirts.source',
            },
            component: markRaw(ScreenQuestionButtons),

        },
        [ScreensEnum.HowManyTShirtsAns]: {
            screenId: ScreensEnum.HowManyTShirtsAns,
            screenNext: ScreensEnum.WasteSlides,
            type: ScreensTypeEnum.SCREEN_ANSWER,
            title: '',
            hideCorrectAnswer: true,
            bgImage: "img/scirt/gifi/75-majic.gif",
            answerKey: 'HowManyTShirts',
            correctAnswer: [75, 'HowManyTShirts.75-shirts'],
            titleAnsOk: 'HowManyTShirts.ans_ok_title',
            titleAnsWrong: 'HowManyTShirts.ans_nok_title',
            textAnsOk: 'HowManyTShirts.ans_ok_text',
            textAnsWrong: 'HowManyTShirts.ans_nok_text',
            textPart1: 'HowManyTShirts.ans_text',
            textPart2: 'HowManyTShirts.ans_text2',
            component: markRaw(ScreenAnswer),
        },

        [ScreensEnum.WasteSlides]: {
            screenId: ScreensEnum.WasteSlides,
            screenNext: ScreensEnum.Statistics,
            type: ScreensTypeEnum.SCREEN_WASTE_CAROUSEL,
            title: 'Q18',
            answerKey: 'WasteSlides',
            textPart1: 'WasteSlides.text1',
            textPart2: 'WasteSlides.text2',
            // TODO: dynamic slides
            slides: {
                0: `img/scirt/${locale}/slide1.png`,
                1: `img/scirt/${locale}/slide2.png`,
                2: `img/scirt/${locale}/slide3.png`,
            },
            slidesReadMore: {
                0: 'WasteSlides.readMore1',
                1: 'WasteSlides.readMore2',
                2: '',
            },
            readMoreModal: {
                title: "WasteSlides.readMoreModal_title",
                slide0: {
                    "text1": "WasteSlides.readMoreModal_slide0_text1",
                    "text2": "WasteSlides.readMoreModal_slide0_text2",
                    "text3": "WasteSlides.readMoreModal_slide0_text3",
                    "text4": "WasteSlides.readMoreModal_slide0_text4",
                    "text5": "WasteSlides.readMoreModal_slide0_text5",
                },
                slide1: {
                    "text1": "WasteSlides.readMoreModal_slide1_text1",
                },
                sources: {
                    slide0: 'WasteSlides.readMoreModal_sources_slide0',
                    slide1: 'WasteSlides.readMoreModal_sources_slide1',
                }
            },
            source: {
                text: 'WasteSlides.source',
            },
            bgStyles: 'background-color: #EDE1DF;',
            component: markRaw(ScreenRightSlides),
        },

        [ScreensEnum.Statistics]: {
            screenId: ScreensEnum.Statistics,
            screenNext: ScreensEnum.ShareIdeasTexts,
            type: ScreensTypeEnum.SCREEN_TEXTS,
            title: 'Q19',
            answerKey: 'Statistics',
            bgImage: 'url("img/scirt/volna.jpg")',
            bgStyles: 'background-size: 50%; ',
            textPart1: 'Statistics.text1',
            textPart2: 'Statistics.text2',
            textPart3: 'Statistics.text3',
            textPart4: 'Statistics.text4',
            nextButtonText: 'Statistics.button',
            component: markRaw(ScreenTextsPink),
        },

        [ScreensEnum.ShareIdeasTexts]: {
            screenId: ScreensEnum.Statistics,
            screenNext: ScreensEnum.ShareIdeasQuestion,
            type: ScreensTypeEnum.SCREEN_TEXTS,
            title: '',
            answerKey: 'ShareIdeasTexts',
            bgImage: 'url("img/scirt/beforeShareBg.png")',
            bgStyles: 'background-size: 50%;',
            textPart1: 'ShareIdeasTexts.text1',
            textPart2: 'ShareIdeasTexts.text2',
            textPart3: 'ShareIdeasTexts.text3',
            textPart4: 'ShareIdeasTexts.text4',
            component: markRaw(ScreenTextsGreen),
        },

        [ScreensEnum.ShareIdeasQuestion]: {
            screenId: ScreensEnum.ShareIdeasQuestion,
            screenNext: ScreensEnum.RateUs,
            type: ScreensTypeEnum.SCREEN_SINGLE_QUESTION,
            title: 'Q20',
            answerKey: 'ShareIdeasQuestion',
            question: 'ShareIdeasQuestion.question',
            placeholder: 'ShareIdeasQuestion.placeholder',
            bgImage: "url('img/scirt/gifi/obleke-in-police.gif')",
            buttonText: 'ShareIdeasQuestion.button',
            component: markRaw(ScreenSingleQuestion),
        },

        [ScreensEnum.RateUs]: {
            screenId: ScreensEnum.RateUs,
            screenNext: ScreensEnum.FinalScreen,
            type: ScreensTypeEnum.SCREEN_RATE_US,
            title: '',
            answerKey: 'RateUs',
            textPart1: 'RateUs.text1',
            // textPart2: 'RateUs.text2',
            buttonText: 'RateUs.button',
            starsColor: '#8BE0B9',
            bgImage: "url('img/scirt/finalBg.jpg')",
            bgStyles: 'background-size: contain;',
            component: markRaw(ScreenRateUs),
        },

        [ScreensEnum.FinalScreen]: {
            screenId: ScreensEnum.FinalScreen,
            screenNext: ScreensEnum.FinalScreen,
            type: ScreensTypeEnum.SCREEN_FINAL,
            title: '',
            answerKey: 'FinalScreen',
            textPart1: 'FinalScreen.text1',
            textPart2: 'FinalScreen.text2',
            buttonText: 'FinalScreen.button',
            bgImage: `url('img/scirt/${locale}/scirtjourney.jpg')`,
            bgStyles: 'background-size: 90%;',
            fbLink: 'https://www.facebook.com/SCIRT-102101585425622',
            instaLink: 'https://www.instagram.com/scirt_/',
            linkedinLink: 'https://www.linkedin.com/company/scirt/',
            component: markRaw(ScreenFinalVideo),
        },


    } as ScreenDefinitions
}
