
import {defineComponent} from 'vue';
import TransitionWrapper from "@/components/elements/TransitionWrapper.vue";
import {ScreensEnum} from '@/Screens.enum';
import {audioPlayerMixin} from "@/mixins/audio";
import {useState} from "@/hooks/state";
import {ScreensTypes} from "@/Screens.types";

const state = useState();

export default defineComponent({
  name: 'App',
  mixins: [audioPlayerMixin],
  components: {
    TransitionWrapper,
  },
  data() {
    const stepInHash = location.hash.substring(1) || ScreensEnum.SCIRT_INTRO;

    return {
      ScreensEnum,
      totalSteps: 5,
      isGoingBack: false,
      currentScreen: Object.values(ScreensEnum).includes(stepInHash as ScreensEnum) ? stepInHash : ScreensEnum.SCIRT_INTRO,
      screenConfigs: {},
      alreadyVisitedScreens: [],
    }
  },

  watch: {
    '$i18n.locale': function (locale) {
      console.log('Locale changed', locale);
      if (this.audioOn) {
        const translatedFile = this.findFileInLocale(this.url, locale)
        if (translatedFile) {
          this.setupAndPlayIfOn(translatedFile)
        } else {
          this.stopAudio()
        }
      }
    }
  },

  computed: {
    deviceClass() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // per browser class
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      // @ts-ignore
      const isChrome = !!window.chrome && (!!window.chrome.app || !!window.chrome.webstore || !!window.chrome.runtime);
      const isEdge = navigator.userAgent.indexOf("Edge") > -1;

      return {
        'is-mobile': isMobile,
        'is-safari': isSafari,
        'is-firefox': isFirefox,
        'is-chrome': isChrome,
        'is-edge': isEdge,
        'is-none': !isMobile && !isSafari && !isFirefox && !isChrome && !isEdge,
      }
    },
  },

  mounted() {
    this.screenConfigs = this.getScreenConfig(this.$t, this.state, this.$i18n.locale);

    this.preloadImages(this.screenConfigs);

    window["_paq"]?.push(['trackPageView'])

    if (location.hash.substring(1) !== this.currentScreen) {
      location.hash = '#' + this.currentScreen;
    }

    window.addEventListener('hashchange', () => {
      const next = location.hash.substring(1) || ScreensEnum.SCIRT_INTRO;
      if (this.currentScreen === next) {
        return;
      }
      this.goTo(next);
    });

    const queryParams = new URLSearchParams(window.location.search)
    this.$i18n.locale = queryParams.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'en';

    console.log('Starting with', this.$i18n.locale, state.hash.value?.length);

    if (!state.hash.value?.length) {
      state.setHash();
    }
  },
  methods: {
    goTo(screen, isGoingBack = false) {
      if (screen === -1) {
        // Remove current screen
        let backScreen = this.alreadyVisitedScreens.pop();
        screen = this.alreadyVisitedScreens.pop() || ScreensEnum.SCIRT_INTRO;

        console.log(screen, backScreen, this.alreadyVisitedScreens)
        this.isGoingBack = true;
      } else {
        this.isGoingBack = this.alreadyVisitedScreens.includes(screen) ? true : isGoingBack;
      }
      console.debug('Going to', screen, 'isGoingBack', this.isGoingBack)

      this.$nextTick(() => {
        this.currentScreen = screen
        location.hash = '#' + this.currentScreen
        this.alreadyVisitedScreens.push(screen)

        window["_paq"]?.push(['trackEvent', 'ScreenChange', this.currentScreen])
      })
    },
    preloadImages(screenConfigs) {
      const images = [];
      Object.values(screenConfigs).forEach((config: ScreensTypes) => {
        if (config.bgImage) {
          // replace url(...) with just the path
          const cleanPath = config.bgImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
          //Skip gifs
          if (cleanPath.endsWith('.gif')) {
            return;
          }

          images.push(cleanPath);
        }
      });

      images.forEach((image) => {
        const img = new Image();
        img.src = image;
      })
    },
  }
});
